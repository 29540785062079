import React from "react";
import "./rates.scss";

const Rates = () => {
  return (
    <div>
      <h2>Tarife</h2>
      <p className="app-text">
        Hanul de Vis este un complex format din două corpuri. Primul este
        alcătuit din parter, care include bucătăria, sala de mese și o baie
        (comune pt cele două corpuri) și mansarda cu două dormitoare, fiecare cu
        intrare separată din exteriorul clădirii. Al doilea corp include trei
        dormitoare cu un hol comun și terasă acoperită. Toate camerele sunt
        dotate cu baie proprie cu cabină de duș și uscător de păr. Camerele de
        la mansardă conțin un pat matrimonial și un pat pentru copii, iar cele
        din al doilea corp câte două paturi single.
      </p>
      <p className="app-text">
        Pe lângă spațiile de cazare în incintă se află și un foișor acoperit, cu
        spațiu pentru grătare și ceaun. Contra cost, se poate utiliza ciubărul
        cu apă caldă, situat în aer liber. Tarifele pentru cazare sunt după cum
        urmează:
      </p>
      <ul className="app-text">
        <li>
          200 RON / cameră / noapte <span className="notice">*</span>
        </li>
        <li>2000 RON / complex / weekend (2 nopți)</li>
      </ul>
      <p className="app-text">
        <span className="notice">*</span> Se închiriază doar pentru cel puțin 4
        camere și minim 2 nopți.
      </p>
      <p className="app-text">
        <span className="glyphicon glyphicon-warning-sign"></span> Intrarea se
        face după ora 16, iar ieșirea până la ora 13.
      </p>
      <p className="app-text">
        <span className="glyphicon glyphicon-warning-sign"></span> Avansul
        achitat nu se returnează, în caz de renunțare sau neprezentare din
        partea clientului.
      </p>
    </div>
  );
};

export default Rates;
