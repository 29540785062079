import React from 'react';
import { Link } from "react-router-dom";
import './header.scss';

const Header = () => {
  return (
    <div className="header">
      <h1 className="header-title"><Link to="/">Hanul de Vis</Link></h1>
      <span className="glyphicon glyphicon-asterisk first"></span>
      <span className="glyphicon glyphicon-asterisk"></span>
      <span className="glyphicon glyphicon-asterisk"></span>
    </div>
  );
}

export default Header