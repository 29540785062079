import React from "react";
import ImageGallery from "react-image-gallery";
import "./gallery.scss";
import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

const Gallery = () => {
  const images = [
    {
      original: "/photos/poza1.jpg",
      thumbnail: "/photos/poza1.jpg",
    },
    {
      original: "/photos/poza2.jpg",
      thumbnail: "/photos/poza2.jpg",
    },
    {
      original: "/photos/poza3.jpg",
      thumbnail: "/photos/poza3.jpg",
    },
    {
      original: "/photos/poza4.jpg",
      thumbnail: "/photos/poza4.jpg",
    },
    {
      original: "/photos/poza5.jpg",
      thumbnail: "/photos/poza5.jpg",
    },
    {
      original: "/photos/poza6.jpg",
      thumbnail: "/photos/poza6.jpg",
    },
    {
      original: "/photos/poza7.jpg",
      thumbnail: "/photos/poza7.jpg",
    },
    {
      original: "/photos/poza8.jpg",
      thumbnail: "/photos/poza8.jpg",
    },
    {
      original: "/photos/poza9.jpg",
      thumbnail: "/photos/poza9.jpg",
    },
    {
      original: "/photos/poza10.jpg",
      thumbnail: "/photos/poza10.jpg",
    },
    {
      original: "/photos/poza11.jpg",
      thumbnail: "/photos/poza11.jpg",
    },
    {
      original: "/photos/poza12.jpg",
      thumbnail: "/photos/poza12.jpg",
    },
    {
      original: "/photos/poza13.jpg",
      thumbnail: "/photos/poza13.jpg",
    },
    {
      original: "/photos/poza14.jpg",
      thumbnail: "/photos/poza14.jpg",
    },
    {
      original: "/photos/poza15.jpg",
      thumbnail: "/photos/poza15.jpg",
    },
    {
      original: "/photos/poza16.jpg",
      thumbnail: "/photos/poza16.jpg",
    },
    {
      original: "/photos/poza17.jpg",
      thumbnail: "/photos/poza17.jpg",
    },
    {
      original: "/photos/poza18.jpg",
      thumbnail: "/photos/poza18.jpg",
    },
    {
      original: "/photos/poza19.jpg",
      thumbnail: "/photos/poza19.jpg",
    },
    {
      original: "/photos/poza20.jpg",
      thumbnail: "/photos/poza20.jpg",
    },
    {
      original: "/photos/poza21.jpg",
      thumbnail: "/photos/poza21.jpg",
    },
    {
      original: "/photos/poza22.jpg",
      thumbnail: "/photos/poza22.jpg",
    },
    {
      original: "/photos/poza23.jpg",
      thumbnail: "/photos/poza23.jpg",
    },
    {
      original: "/photos/poza24.jpg",
      thumbnail: "/photos/poza24.jpg",
    },
    {
      original: "/photos/poza25.jpg",
      thumbnail: "/photos/poza25.jpg",
    },
  ];

  return (
    <div>
      <h2>Galerie</h2>
      <ImageGallery items={images} />
    </div>
  );
};

export default Gallery;
