import React from 'react';
import './activities.scss';

const Activities = () => {
  return (
    <div>
        <h2>Activități</h2>
        <div className="list"> 
          
          <div className="list-item">
            <img src="/photos/LaculBelisFantanele.jpg" alt=""></img>
          </div>
          <div className="list-item text-list-item">
            <h3>Lacul Beliș-Fântânele</h3>
            <p className="app-text">
            Situat la 8 km de Hanul de Vis, barajul amenajat pe râul Someșul Cald a fost construit în perioada 1970-1974, având scop hidroenergetic. Cu o 
            suprafață de aproximativ 10 kmp și o lungime de 13 km, lacul Beliș-Fântânele este cel mai intins din județul Cluj. Pe malurile sale se întinde 
            stațiunea turistică Beliș, a cărei amplasare prezentă se datorează construirii barajului. În acea perioadă numeroase localități, cum ar fi 
            Milioan, Pleș, Dădești, Bolojești sau Giurcuța de Jos, au fost înghițite de acumularea de apă, ruine ale acestora putând fi observate în 
            verile foarte secetoase. Cea mai cunoscută dintre acestea este fostul edificiu al bisericii din Giurcuța de Jos.
            </p>
          </div>
          <div className="list-item text-list-item">
            <h3>Cascada Vălul Miresei</h3>
            <p className="app-text">
            La 26 de km față de Hanul de Vis, cu o infrastructură care permite accesul auto până la baza sa, se află una dintre cele mai frumoase cascade ale 
            țării, Vălul Miresei. Având o cădere de peste 30 de metri, cascada oferă un peisaj deosebit inclusiv pe timp de iarnă, când peretele stâncos îngheață 
            și devine un loc de joacă pentru pasionații de escaladă. Apariția căderii de apă este legată de o legendă potrivit căreia o mireasă și-ar fi găsit 
            sfârșitul căzând de pe stâncile din zona respectivă, în timp ce voalul a rămas agățat de una dintre ele. Lacrimile nuntașilor care o însoțeau 
            au fost atât de numeroase încât au dat naștere cascadei. Desigur, mult mai probabil ar fi ca numele acesteia să provină de la forma sa asemănătoare
            unui voal.
            </p>
          </div>
          <div className="list-item">
            <img src="/photos/ValulMiresei.jpg" alt=""></img>
          </div>

          <div className="list-item">
            <img src="/photos/PartiaMarisel.jpg" alt=""></img>
          </div>
          <div className="list-item text-list-item">
            <h3>Pârtiile Mărișel</h3>
            <p className="app-text">
            La doar 19 km de Hanul de Vis pasionații de sporturi de iarnă vor găsi unele dintre cele mai noi pârtii de schi din zonă, pârtiile Mărișel. Locația 
            acestora este una deosebită, oferind peisaje impresionante ale lacului de acumulare Beliș-Fântânele și ale piscurilor Munților Apuseni. Complexul 
            își întâmpină oaspeții cu 3 pârtii de schi, având diverse grade de dificultate, și una de sănius. Urcarea spre vârf se poate face atât pe instalații 
            de teleschi cât și cu telescaun.
            </p>
          </div>

        </div>
    </div>
  );
}

export default Activities