import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <label className="app-text">Date firmă:</label>
      <div className="app-text">
        Han S Adrian Niculae PFA
        <br />
        Sat Călățele, Com. Călățele, Nr. 589, Județ Cluj
        <br />
        CUI 36220651, Nr. Reg. Com. F12/701/17.06.2016
        <br />
        &copy; {new Date().getFullYear()} Hanul de Vis
      </div>
    </div>
  );
};

export default Footer;
