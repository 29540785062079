import React from 'react';
import { Switch, Route } from "react-router-dom";
import './content.scss';
import AboutUs from '../AboutUs/aboutUs';
import Contact from '../Contact/contact';
import Gallery from '../Gallery/gallery';
import Rates from '../Rates/rates';
import Activities from '../Activities/activities';

const Content = () => {
  return (
    <div className="content-container">
      <div className="content">
        <Switch>
          <Route path="/" exact component={AboutUs} />
          <Route path="/galerie" exact component={Gallery} />
          <Route path="/tarife" exact component={Rates} />
          <Route path="/activitati" exact component={Activities} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
    </div>
  );
}

export default Content