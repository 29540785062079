import React from 'react';
import ReactDOM from 'react-dom';
import GuestHouse from './app'
import './index.scss';

// ========================================

ReactDOM.render(
  <GuestHouse />,
  document.getElementById('root')
);
