import React from 'react';
import Header from './Header/header';
import Menu from './Menu/menu';
import Content from './Content/content';
import Footer from './Footer/footer';
import { BrowserRouter as Router } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import ReactGA from 'react-ga';

const GuestHouse = () => {
  initializeReactGA();
  const isPhone = isMobileOnly ? 'isPhone' : '';
  return (
    <Router>
      <div className={`application ${isPhone}`}>
        <div className="full-page">
          <Header />
          <Menu />
          <Content />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

function initializeReactGA() {
  ReactGA.initialize('UA-150603070-1');
  ReactGA.pageview('/');
}

export default GuestHouse