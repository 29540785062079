import React from 'react';
import './contact.scss';

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <div className="contact-data">
        <div className="contact-group">
          <label className="app-text contact-label">Adresa:</label>
          <div className="app-text contact-text">Sat Călățele-Pădure, nr. 589<br />Com. Călățele, Jud. Cluj (zona Beliș)</div>
        </div>
        <div>
          <label className="app-text contact-label">Telefon:</label>
          <div className="app-text contact-text">
            <span className="glyphicon glyphicon-earphone"></span>0747 082653<br />
            <span className="glyphicon glyphicon-earphone"></span>0752 572404
          </div>
        </div>
      </div>
      <div className="googleMap">
        <iframe title="Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCBdAXn0UQzYaD4Ui5PgYnmE4wvxPpyjVI&q=46.700710,23.020942&center=46.700710,23.020942&zoom=14&maptype=satellite"></iframe>
      </div>
    </div>
  );
}

export default Contact