import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { slide as BurgerMenu } from 'react-burger-menu';
import ReactGA from 'react-ga';
import './menu.scss';

const Menu = () => {
  const hideIfMobile = isMobile ? 'hide' : '';
  const hideIfNotMobile = isMobile ? '' : 'hide';
  const [isOpenFlag, setOpenMenu] = useState(false);
  
  const closeMenu = (section) => {
    logGaEvent(section);
    setOpenMenu(true);
    setTimeout(() => { setOpenMenu(false); }, 0);
  };

  const logGaEvent = (section) => {
    ReactGA.event({
      category: 'Menu item',
      action: 'Click ' + section
    });
  }

  return <div className="menu-container">
    <nav className="menu app-text">
      <div className={`${hideIfNotMobile}`}>
        <BurgerMenu disableAutoFocus isOpen={ isOpenFlag }>
          <Link to="/" onClick={() => closeMenu('BM-Home')}>Despre noi</Link>
          <Link to="/galerie" onClick={() => closeMenu('BM-Gallery')}>Galerie</Link>
          <Link to="/tarife" onClick={() => closeMenu('BM-Rates')}>Tarife</Link>
          <Link to="/activitati" onClick={() => closeMenu('BM-Activities')}>Activități</Link>
          <Link to="/contact" onClick={() => closeMenu('BM-Contact')}>Contact</Link>
        </BurgerMenu>
      </div>
      <Link className={`${hideIfMobile}`} to="/" onClick={() => logGaEvent('Home')}>Despre noi</Link>
      <Link className={`${hideIfMobile}`} to="/galerie" onClick={() => logGaEvent('Gallery')}>Galerie</Link>
      <Link className={`${hideIfMobile}`} to="/tarife" onClick={() => logGaEvent('Rates')}>Tarife</Link>
      <Link className={`${hideIfMobile}`} to="/activitati" onClick={() => logGaEvent('Activities')}>Activități</Link>
      <Link className={`${hideIfMobile}`} to="/contact" onClick={() => logGaEvent('Contact')}>Contact</Link>
      <a href="https://www.facebook.com/hanuldevis/" className="fa fa-facebook"><span></span></a>
    </nav>
    
  </div>;
}

export default Menu